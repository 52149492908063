import React, { Component } from 'react'
import NewsItem from './NewsItem'
import Spinner from './Spinner';
import PropTypes from 'prop-types';

export class News extends Component {
  static defualtProps = {
    pageSize: 5,
    category: "business",
    country: "in",
  };

  static propTypes = {
    pageSize: PropTypes.number,
    category: PropTypes.string,
    country:PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      article: [],
      loading: false,
      page: 1,
    };
    let cap_title =
      this.props.category.charAt(0).toUpperCase() + this.props.category.substr(1);
    document.title = `${cap_title} - NewsApp`;
  }

  async componentDidMount() {
    this.props.setProgress(0);
    let url = `https://newsapi.org/v2/top-headlines?country=${this.props.country}&category=${this.props.category}&apiKey=${this.props.apiKey}&page=${this.state.page}&pageSize=${this.props.pageSize}`;
    this.setState({ loading: true });
    let data = await fetch(url);
    let parsedData = await data.json();
    this.props.setProgress(50);
    this.setState({
      article: parsedData?.articles?parsedData?.articles:[],
      loading: false,
      page: 1,
      totalResults: parsedData.totalResults,
    });
    this.props.setProgress(100);
  }

  leftPagination = async () => {
    this.props.setProgress(0);
    let url = `https://newsapi.org/v2/top-headlines?country=${
      this.props.country
    }&category=${
      this.props.category
    }&apiKey=${this.props.apiKey}&page=${
      this.state.page - 1
    }&pageSize=${this.props.pageSize}`;
    this.setState({ loading: true });
    console.log("leftPagination", url);
    let data = await fetch(url);
    let parsedData = await data.json();
    this.props.setProgress(50);
    this.setState({
      article: parsedData.articles,
      page: this.state.page - 1,
      loading: false,
    });
    this.props.setProgress(100);
  };

  rightPagination = async () => {
    this.props.setProgress(0);
    if (
      this.state.page + 1 <=
      Math.ceil(this.state.totalResults / this.props.pageSize)
    ) {
      let url = `https://newsapi.org/v2/top-headlines?country=${
        this.props.country
      }&category=${
        this.props.category
      }&apiKey=${this.props.apiKey}&page=${
        this.state.page + 1
      }&pageSize=${this.props.pageSize}`;
      this.setState({ loading: true });
      console.log("rightPagination", url);
      let data = await fetch(url);
      let parsedData = await data.json();
      this.props.setProgress(50);
      this.setState({
        article: parsedData.articles,
        page: this.state.page + 1,
        loading: false,
      });
    }
    this.props.setProgress(100);
  };

  render() {
    return (
      <div className="container py-5 position-relative">
        <h1 className="text-center fw-bold mb-5">
          Top{" "}
          {this.props.category.charAt(0).toUpperCase() +
            this.props.category.substr(1)}{" "}
          HeadLines
        </h1>
        {this.state.loading && <Spinner />}
        {this.state?.article.length === 0 && (
          <h3 className="text-center">No Updates</h3>
        )}
        <div className="row" style={{ rowGap: "2rem" }}>
          {!this.state.loading &&
            this.state?.article.length > 0 &&
            this.state?.article.map((ele, i) => {
              return (
                <div className="col-md-4" key={i}>
                  <NewsItem
                    title={ele.title}
                    imgUrl={ele.urlToImage}
                    desc={ele.description}
                    targetUrl={ele.url}
                    author={ele.author}
                    dt={ele.publishedAt}
                    name={ele.source.name}
                  ></NewsItem>
                </div>
              );
            })}
        </div>
        {!this.state.loading && this.state?.article.length > 0 && (
          <div className="d-flex justify-content-center my-3">
            <button
              onClick={this.leftPagination}
              type="button"
              className="btn btn-dark mx-3"
              disabled={this.state.page <= 1}
            >
              &larr; Prev
            </button>
            <button
              onClick={this.rightPagination}
              type="button"
              className="btn btn-dark"
              disabled={
                this.state.page + 1 >
                Math.ceil(this.state.totalResults / this.props.pageSize)
              }
            >
              Next &rarr;
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default News