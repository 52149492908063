import React, { Component } from 'react'
import loading from '../assets/img/loading.gif'

export default class Spinner extends Component {
  render() {
    return (
      <div
        className="w-100 h-100 position-absolute d-flex justify-content-center align-items-center"
        style={{ background: "#D9AFD9", zIndex: "1" }}
      >
        <img style={{ background: "#fff" }} src={loading} alt="loading" />
      </div>
    );
  }
}
