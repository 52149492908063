import React, { Component } from "react";

export class NewsItem extends Component {

  render() {
    let { title, imgUrl, desc, targetUrl, author , dt, name} = this.props;
    return (
      <div className="card" style={{ height: "30rem", position: "relative" }}>
        <div
          className="position-absolute"
          style={{
            backgroundImage:
              "linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12)",
            color: "#fff",
            padding: "1px 3px",
          }}
        >
          {name ? name : "Unknown"}
        </div>
        <div style={{ width: "100%", height: "13rem" }}>
          <img
            src={imgUrl ? imgUrl : "https://placehold.co/600x400?text=No+Image"}
            className="card-img-top"
            alt=""
            style={{ width: "100%", height: "100%", objectFit:"cover" }}
          />
        </div>
        <div className="card-body p-2 d-flex flex-column justify-content-between align-items-start">
          <div>
            <h5
              className="card-title text-truncate text-wrap"
              // style={{
              //   overflow: "hidden",
              //   textOverflow: "ellipsis",
              // }}
            >
              {title ? title.substring(0, 50) : ""}
            </h5>
            <p
              className="card-text text-truncate text-wrap"
              // style={{
              //   height: "8rem",
              //   overflow: "auto",
              // }}
            >
              {desc ? desc.substring(0, 250) : ""}
            </p>
          </div>
          <div className="d-flex flex-column gap-2">
            <a
              href={targetUrl}
              target="_blank"
              className="btn btn-sm w-50 text-white"
              style={{ backgroundColor: "#002244" }}
            >
              Read More
            </a>
            <small className="text-muted">
              by {author} on {dt}
            </small>
          </div>
        </div>
      </div>
    );
  }
}

export default NewsItem;
